import Vue from 'vue';
import Router from 'vue-router';
import VueMeta from 'vue-meta';
import store from '@/store/index';

// Consts for admin routes
const USER_LANGUAGE = (navigator.language || navigator.userLanguage).replace('-', '_');
const CHURCH_CODE = window.location.hostname.split('.')[0];

Vue.use(Router);
Vue.use(VueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true,
});

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },

  routes: [
    {
      path: '',
      meta: {
        layout: 'HOME',
      },
      component: () => import(/* webpackChunkName: "church" */ '@/layouts/Home.vue'),

      children: [
        {
          path: '',
          name: 'churchHome',
          component: () => import(/* webpackChunkName: "church" */ '@/views/church/Home.vue'),
        },
      ],
    },
    {
      path: '',
      meta: {
        layout: 'DEFAULT',
      },
      component: () => import(/* webpackChunkName: "church" */ '@/layouts/Default.vue'),

      children: [
        {
          path: 'about',
          name: 'ChurchAbout',
          component: () => import(/* webpackChunkName: "church-about" */ '@/views/church/About.vue'),
        },
        {
          path: 'register',
          name: 'ChurchRegister',
          component: () => import(/* webpackChunkName: "church-register" */ '@/views/church/Register.vue'),
        },
        {
          path: 'bible',
          name: 'churchBible',
          component: () => import(/* webpackChunkName: "church-bible" */ '@/views/bible/Home.vue'),
        },
        {
          path: 'churchs',
          name: 'ChurchList',
          component: () => import(/* webpackChunkName: "church-list" */ '@/views/church/360/Index.vue'),
        },
        {
          path: 'live',
          name: 'Live',
          meta: {
            module: 'MOD_CULTO',
          },
          component: () => import(/* webpackChunkName: "live" */ '@/views/live/Home.vue'),
        },
        {
          path: 'event',
          name: 'eventAll',
          meta: {
            module: 'MOD_EVENTO',
          },
          component: () => import(/* webpackChunkName: "all-events" */ '@/views/event/All.vue'),
        },
        {
          path: 'event/:eventId?',
          meta: {
            module: 'MOD_EVENTO',
          },
          props: (route) => ({
            eventId: Number(route.params.eventId),
            buyingStep: route.query.buyingStep,
            fillTicket: Number(route.query.fillTicket),
          }),
          component: () => import(/* webpackChunkName: "event" */ '@/views/event/single/Base.vue'),

          children: [
            {
              path: '',
              name: 'eventSingle',
              component: () => import(/* webpackChunkName: "event" */ '@/views/event/single/Single.vue'),
            },
            {
              path: 'tickets',
              name: 'eventTickets',
              meta: {
                requiresAuth: true,
              },
              component: () => import(/* webpackChunkName: "event" */ '@/views/event/single/Tickets.vue'),
            },
          ],
        },
        {
          path: 'checkout',
          component: () => import(/* webpackChunkName: "checkout" */ '@/views/common/BaseRouterView.vue'),

          children: [
            {
              path: '',
              name: 'checkout',
              props: true,
              component: () => import(/* webpackChunkName: "checkout" */ '@/views/checkout/Payment.vue'),
            },
            {
              path: 'thank-you',
              name: 'checkoutSuccess',
              props: true,
              component: () => import(/* webpackChunkName: "checkout-thankyou" */ '@/views/checkout/Success.vue'),
            },
          ],
        },
      ],
    },
    // --- Give
    {
      path: '',
      meta: {
        layout: 'DEFAULT',
      },
      component: () => import(/* webpackChunkName: "church" */ '@/layouts/Default.vue'),
      children: [
        {
          path: 'doacoes',
          redirect: 'give',
        },
        {
          path: 'give',
          meta: {
            module: 'MOD_DOACAO',
            layout: 'DEFAULT',
          },
          component: () => import(/* webpackChunkName: "give" */ '@/views/give/Base.vue'),

          children: [
            {
              path: '',
              name: 'giveOptions',
              component: () => import(/* webpackChunkName: "give-options" */ '@/views/give/Single.vue'),
            },
          ],
        },
      ],
    },
    // --- Groups
    {
      path: '/groups',
      meta: {
        layout: 'DEFAULT',
        module: 'MOD_CELULA',
        requiresAuth: false,
      },
      component: () => import(/* webpackChunkName: "celulas" */ '@/layouts/Default.vue'),

      children: [
        {
          path: '',
          name: 'celulasAll',
          component: () => import(/* webpackChunkName: "subscription" */ '@/views/celulas/Index.vue'),
        },
        {
          path: '/groups/:celulaId',
          name: 'celulaSingle',
          props: (route) => ({
            celulaId: Number(route.params.celulaId),
          }),
          component: () => import(/* webpackChunkName: "subscription" */ '@/views/celulas/Single.vue'),
        },
        {
          path: '/groups/request/success',
          name: 'celulaRequest',
          component: () => import(/* webpackChunkName: "subscription" */ '@/views/celulas/Success.vue'),
        },
        {
          path: '/groups/:celulaId/meetings',
          name: 'celulaMeetings',
          meta: {
            requiresAuth: true,
          },
          props: (route) => ({
            celulaId: Number(route.params.celulaId),
          }),
          component: () => import(/* webpackChunkName: "subscription" */ '@/views/celulas/meetings/Index.vue'),
        },
        {
          path: '/groups/:celulaId/meetings/:meetingId',
          name: 'celulaMeeting',
          meta: {
            requiresAuth: true,
          },
          props: (route) => ({
            celulaId: Number(route.params.celulaId),
            meetingId: Number(route.params.meetingId),
          }),
          component: () => import(/* webpackChunkName: "meetings" */ '@/views/celulas/meetings/Single.vue'),
        },
      ],
    },
    // --- Studies
    {
      path: '/studies',
      meta: {
        layout: 'DEFAULT',
        module: 'MOD_CELULA',
      },
      component: () => import(/* webpackChunkName: "studies" */ '@/layouts/Default.vue'),

      children: [
        {
          path: '',
          name: 'studiesAll',
          component: () => import(/* webpackChunkName: "studiesAll" */ '@/views/celulas/studies/Index.vue'),
        },
        {
          path: ':studiesId',
          name: 'studiesSingle',
          props: (route) => ({
            studiesId: Number(route.params.studiesId),
          }),
          component: () => import(/* webpackChunkName: "studiesSingle" */ '@/views/celulas/studies/Single.vue'),
        },
      ],
    },

    // --- Access
    {
      path: '',
      meta: {
        layout: 'DEFAULT',
      },
      component: () => import(/* webpackChunkName: "church" */ '@/layouts/Default.vue'),
      children: [
        {
          path: 'access',
          name: 'userAccess',
          props: (route) => ({ redirectTo: route.query.redirectTo }),
          meta: {
            onlyVisitors: true,
          },
          component: () => import(/* webpackChunkName: "access" */ '@/views/access/Index.vue'),
        },
        {
          path: 'login',
          name: 'userLogin',
          redirect: {
            name: 'userAccess',
          },
        },
        {
          path: 'register',
          name: 'userRegister',
          redirect: {
            name: 'userAccess',
          },
        },
        {
          path: 'reset-password',
          name: 'userResetPassword',
          redirect: {
            name: 'userAccess',
          },
        },
      ],
    },
    // --- Account
    {
      path: '/user',
      meta: {
        layout: 'DEFAULT',
        requiresAuth: true,
      },
      component: () => import(/* webpackChunkName: "user" */ '@/layouts/Default.vue'),

      children: [
        {
          path: '',
          name: 'userAccount',
          component: () => import(/* webpackChunkName: "user-account" */ '@/views/user/Index.vue'),
        },
        {
          path: 'data',
          name: 'userData',
          props: (route) => ({ redirectTo: route.query.redirectTo }),
          component: () => import(/* webpackChunkName: "user-data" */ '@/views/user/UserData.vue'),
        },
        {
          path: 'data-update',
          name: 'userDataUpdateForPayment',
          props: (route) => ({ redirectTo: route.query.redirectTo }),
          component: () => import(/* webpackChunkName: "user-data-update" */ '@/views/user/UserDataUpdateForPayment.vue'),
        },
        {
          path: 'update',
          name: 'userUpdate',
          redirect: {
            name: 'userData',
          },
        },
        {
          path: 'change-password',
          name: 'userChangePassword',
          component: () => import(/* webpackChunkName: "user-change-password" */ '@/views/user/changePassword/Index.vue'),
        },
        {
          path: 'change-password/success',
          name: 'userChangedPassword',
          component: () => import(/* webpackChunkName: "user-change-password" */ '@/views/user/changePassword/Success.vue'),
        },
        {
          path: 'notes',
          name: 'userNotes',
          component: () => import(/* webpackChunkName: "user-notes" */ '@/views/user/notes/Index.vue'),
        },
        {
          path: 'notes/:noteId?',
          name: 'userNotesSingle',
          props: (route) => ({ noteId: Number(route.params.noteId) }),
          component: () => import(/* webpackChunkName: "user-notes" */ '@/views/user/notes/Single.vue'),
        },
        {
          path: 'notes/delete',
          name: 'userNotesDelete',
          props: true,
          component: () => import(/* webpackChunkName: "user-notes" */ '@/views/user/notes/Delete.vue'),
        },
        {
          path: 'registrations',
          component: () => import(/* webpackChunkName: "user-event" */ '@/views/user/event/Base.vue'),

          children: [
            {
              path: '',
              name: 'userEvents',
              component: () => import(/* webpackChunkName: "user-event" */ '@/views/user/event/Index.vue'),
            },
            {
              path: ':eventId/tickets',
              name: 'userEventTickets',
              props: true,
              component: () => import(/* webpackChunkName: "user-event" */ '@/views/user/event/Tickets.vue'),
            },
            {
              path: ':eventId/ticket/:ticketId/content',
              props: true,
              component: () => import(/* webpackChunkName: "user-event" */ '@/views/user/event/content/Base.vue'),

              children: [
                {
                  path: '',
                  name: 'userEventTicketContent',
                  props: true,
                  component: () => import(/* webpackChunkName: "user-event" */ '@/views/user/event/content/Index.vue'),
                },
                {
                  path: ':contentId',
                  name: 'userEventTicketContentSingle',
                  props: true,
                  component: () => import(/* webpackChunkName: "user-event" */ '@/views/user/event/content/Single.vue'),
                },
              ],
            },
          ],
        },
        {
          path: 'prayer',
          name: 'userPrayer',
          meta: {
            module: 'MOD_MURAL_ORACAO',
          },
          component: () => import(/* webpackChunkName: "user prayer" */ '@/views/user/prayer/Index.vue'),
        },
        {
          path: 'prayer/:prayerId',
          name: 'userPrayerSingle',
          props: (route) => ({ prayerId: String(route.params.prayerId) }),
          component: () => import(/* webpackChunkName: "user prayer" */ '@/views/prayer/Single.vue'),
        },
        {
          path: 'payment',
          name: 'userPayments',
          component: () => import(/* webpackChunkName: "user-payment" */ '@/views/user/payment/Base.vue'),

          // Temp Redirect to Home
          redirect: {
            name: 'churchHome',
          },

          children: [
            {
              path: ':paymentCode',
              name: 'userPaymentStatus',
              props: true,
              component: () => import(/* webpackChunkName: "user-payment" */ '@/views/user/payment/Status.vue'),
            },
          ],
        },
        {
          path: 'cards',
          name: 'userCards',
          component: () => import(/* webpackChunkName: "user-cards" */ '@/views/user/cards/Index.vue'),
        },
        {
          path: 'addcard',
          name: 'userAddCard',
          component: () => import(/* webpackChunkName: "user-add-card" */ '@/views/user/cards/AddCreditCard.vue'),
        },
        {
          path: 'deletecard',
          name: 'userDeleteCard',
          component: () => import(/* webpackChunkName: "user-add-card" */ '@/views/user/cards/DeleteCreditCard.vue'),
        },
        {
          path: 'delete-user',
          name: 'deleteUser',
          component: () => import(/* webpackChunkName: "delete-user" */ '@/views/user/delete/Index.vue'),
        },
        {
          path: 'confirm-delete-user',
          name: 'confirmDeleteUser',
          component: () => import(/* webpackChunkName: "delete-user" */ '@/views/user/delete/Confirm.vue'),
        },
        {
          path: 'delete-user-success',
          name: 'DeleteUserSuccess',
          component: () => import(/* webpackChunkName: "delete-user" */ '@/views/user/delete/Success.vue'),
        },
        {
          path: 'church',
          name: 'userChangeChurch',
          component: () => import(/* webpackChunkName: "user-account" */ '@/views/user/church/Index.vue'),
        },
      ],
    },
    // --- Devotionals
    {
      path: '/devotionals',
      meta: {
        layout: 'DEFAULT',
        module: 'MOD_DEVOCIONAL',
        requiresAuth: false,
      },
      component: () => import(/* webpackChunkName: "devotional" */ '@/layouts/Default.vue'),

      children: [
        {
          path: '',
          name: 'devotionalAll',
          component: () => import(/* webpackChunkName: "devotional" */ '@/views/devotional/Index.vue'),
        },
        {
          path: ':devotionalId',
          name: 'devotionalSingle',
          props: (route) => ({ devotionalId: Number(route.params.devotionalId) }),
          component: () => import(/* webpackChunkName: "devotional" */ '@/views/devotional/Single.vue'),
        },
      ],
    },
    // --- News
    {
      path: '/news',
      meta: {
        layout: 'DEFAULT',
        module: 'MOD_NOTICIA',
        requiresAuth: false,
      },
      component: () => import(/* webpackChunkName: "news" */ '@/layouts/Default.vue'),

      children: [
        {
          path: '',
          name: 'newsAll',
          component: () => import(/* webpackChunkName: "news" */ '@/views/news/Index.vue'),
        },
        {
          path: ':newsId',
          name: 'newsSingle',
          props: (route) => ({ newsId: Number(route.params.newsId) }),
          component: () => import(/* webpackChunkName: "news" */ '@/views/news/Single.vue'),
        },
      ],
    },
    // --- Ministery
    {
      path: '/ministries',
      meta: {
        layout: 'DEFAULT',
        // module: 'MOD_MINISTRIES',
        requiresAuth: false,
      },
      component: () => import(/* webpackChunkName: "ministery" */ '@/layouts/Default.vue'),

      children: [
        {
          path: '',
          name: 'ministriesAll',
          component: () => import(/* webpackChunkName: "ministery" */ '@/views/ministries/Index.vue'),
        },
        {
          path: ':ministryId',
          name: 'ministriesSingle',
          props: (route) => ({ ministryId: Number(route.params.ministryId) }),
          component: () => import(/* webpackChunkName: "ministery" */ '@/views/ministries/Single.vue'),
        },
        {
          path: ':ministryId/projects/:projectId',
          name: 'ministriesProject',
          props: (route) => ({
            ministryId: Number(route.params.ministryId),
            projectId: Number(route.params.projectId),
          }),
          component: () => import(/* webpackChunkName: "ministery" */ '@/views/ministries/Project.vue'),
        },
      ],
    },
    // --- Ebs
    {
      path: '/ebds',
      meta: {
        layout: 'DEFAULT',
        module: 'MOD_EBD',
        requiresAuth: false,
      },
      component: () => import(/* webpackChunkName: "devotional" */ '@/layouts/Default.vue'),

      children: [
        {
          path: '',
          name: 'ebdAll',
          component: () => import(/* webpackChunkName: "devotional" */ '@/views/ebd/Index.vue'),
        },
        {
          path: ':ebdSlug',
          name: 'ebdSingle',
          props: (route) => ({ ebdSlug: String(route.params.ebdSlug) }),
          component: () => import(/* webpackChunkName: "devotional" */ '@/views/ebd/Single.vue'),
        },
      ],
    },
    // --- Publication Types
    {
      path: '/publication-types',
      meta: {
        layout: 'DEFAULT',
        requiresAuth: false,
        module: 'MOD_PUBLICACAO',
      },
      component: () => import(/* webpackChunkName: "publication-types" */ '@/layouts/Default.vue'),

      children: [
        {
          path: '',
          name: 'publicationTypesAll',
          component: () => import(/* webpackChunkName: "publication-types" */ '@/views/publication-types/Index.vue'),
        },
      ],
    },

    // --- Publications
    {
      path: '/publications',
      meta: {
        layout: 'DEFAULT',
        requiresAuth: false,
      },
      component: () => import(/* webpackChunkName: "publications" */ '@/layouts/Default.vue'),

      children: [
        {
          path: '',
          name: 'publicationsAll',
          component: () => import(/* webpackChunkName: "publications" */ '@/views/publications/Index.vue'),
        },
        {
          path: ':publicationSlug',
          name: 'publicationSingle',
          props: (route) => ({ publicationSlug: String(route.params.publicationSlug) }),
          component: () => import(/* webpackChunkName: "devotional" */ '@/views/publications/Single.vue'),
        },
      ],
    },
    // --- Videos
    {
      path: '/videos',
      meta: {
        layout: 'DEFAULT',
        // TODO: Modulo precisa vir da api
        // module: 'MOD_VIDEOS',
        requiresAuth: false,
      },
      component: () => import(/* webpackChunkName: "videos" */ '@/layouts/Default.vue'),

      children: [
        {
          path: '',
          name: 'videoAll',
          component: () => import(/* webpackChunkName: "videos" */ '@/views/videos/Index.vue'),
        },
        {
          path: ':videoId',
          name: 'videoSingle',
          props: (route) => ({ videoId: String(route.params.videoId) }),
          component: () => import(/* webpackChunkName: "videos" */ '@/views/videos/Single.vue'),
        },
      ],
    },
    // --- Prayers
    {
      path: '/prayer',
      meta: {
        layout: 'DEFAULT',
        module: 'MOD_MURAL_ORACAO',
      },
      component: () => import(/* webpackChunkName: "prayer" */ '@/layouts/Default.vue'),

      children: [
        {
          path: '',
          name: 'prayerAll',
          component: () => import(/* webpackChunkName: "prayer" */ '@/views/prayer/Index.vue'),
        },
        {
          path: 'new',
          name: 'prayerNew',
          props: (route) => ({ redirectTo: route.query.redirectTo }),
          meta: {
            requiresAuth: true,
          },
          component: () => import(/* webpackChunkName: "prayer" */ '@/views/prayer/New.vue'),
        },
        {
          path: 'new/success',
          name: 'prayerSuccessRequest',
          component: () => import(/* webpackChunkName: "prayer" */ '@/views/prayer/Success.vue'),
        },
        {
          path: ':prayerId',
          name: 'prayerSingle',
          props: (route) => ({ prayerId: String(route.params.prayerId) }),
          component: () => import(/* webpackChunkName: "prayer" */ '@/views/prayer/Single.vue'),
        },
        {
          path: 'delete',
          name: 'prayerDelete',
          props: (route) => ({ prayerId: String(route.params.prayerId) }),
          component: () => import(/* webpackChunkName: "prayer delete" */ '@/views/prayer/Delete.vue'),
        },
        {
          path: 'delete/success',
          name: 'prayerDeleteSuccess',
          component: () => import(/* webpackChunkName: "prayer delete" */ '@/views/prayer/DeleteSuccess.vue'),
        },
      ],
    },
    // --- Subscription
    {
      path: '/subscription',

      children: [
        {
          path: '*',
          redirect: {
            name: 'inpcPlayHome',
          },
        },
      ],
    },
    // --- InPeace Play
    {
      path: '/play',
      meta: {
        layout: 'INPC_PLAY',
        module: 'MOD_ASSINATURA',
      },
      component: () => import(/* webpackChunkName: "inpeace-play" */ '@/layouts/inpcPlay.vue'),

      children: [
        {
          path: '',
          name: 'inpcPlayHome',
          component: () => import(/* webpackChunkName: "inpeace-play" */ '@/views/inpcPlay/Index.vue'),
        },
        {
          path: 'plans',
          name: 'inpcPlayPlans',
          component: () => import(/* webpackChunkName: "inpeace-play" */ '@/views/inpcPlay/Plans.vue'),
        },
        {
          path: 'browse',
          name: 'inpcPlayBrowse',
          component: () => import(/* webpackChunkName: "inpeace-play" */ '@/views/inpcPlay/Browse.vue'),
        },
        {
          path: 'video/:id',
          name: 'inpcPlayVideoData',
          component: () => import(/* webpackChunkName: "inpeace-play" */ '@/views/inpcPlay/SingleVideo.vue'),
        },
        {
          path: 'collection/:id',
          name: 'inpcPlayCollectionData',
          component: () => import(/* webpackChunkName: "inpeace-play" */ '@/views/inpcPlay/SingleCollection.vue'),
        },
        {
          path: 'watch/:id',
          name: 'inpcPlayWatch',
          meta: {
            requiresAuth: true,
          },
          component: () => import(/* webpackChunkName: "inpeace-play" */ '@/views/inpcPlay/Watch.vue'),
        },
        {
          path: 'search',
          name: 'inpcPlaySearch',
          component: () => import(/* webpackChunkName: "inpeace-play" */ '@/views/inpcPlay/Search.vue'),
        },
        {
          path: '*',
          redirect: {
            name: 'inpcPlayHome',
          },
        },
      ],
    },
    // --- Podcast
    {
      path: '/podcast',
      meta: {
        layout: 'DEFAULT',
        // TODO: Colocar quando houver configuração do módulo no admin
        // module: 'MOD_',
        requiresAuth: false,
      },
      component: () => import(/* webpackChunkName: "podcast" */ '@/layouts/Default.vue'),

      children: [
        {
          path: '',
          name: 'podcastIndex',
          component: () => import(/* webpackChunkName: "podcast" */ '@/views/podcast/Index.vue'),
        },
      ],
    },
    // --- Embed routes
    {
      path: '/embed',
      meta: {
        layout: 'EMBED',
      },
      component: () => import(/* webpackChunkName: "embed" */ '@/layouts/Embed.vue'),

      children: [
        {
          path: 'chat',
          name: 'chatEmbed',
          props: (route) => ({
            channel: route.query.channel,
            username: route.query.username,
            limitMessages: route.query.limitMessages,
            showManagement: route.query.showManagement,
          }),
          component: () => import(/* webpackChunkName: "chat-embed" */ '@/views/embed/Chat.vue'),
        },
        {
          path: 'praise',
          name: 'praiseEmbed',
          props: (route) => ({
            praiseId: route.query.praiseId,
            embedColor: route.query.embedColor,
          }),
          component: () => import(/* webpackChunkName: "prase-embed" */ '@/views/embed/Praise.vue'),
        },
        {
          path: 'player',
          name: 'playerEmbed',
          props: (route) => ({
            service: route.query.service,
            code: route.query.code,
            html: route.query.html,
            url: route.query.url,
          }),
          component: () => import(/* webpackChunkName: "player-embed" */ '@/views/embed/Player.vue'),
        },
        {
          path: 'print-member-card',
          name: 'PrintMemberCard',
          props: (route) => ({
            userCardEmail: route.query.userCardEmail,
            showCard: route.query.showCard,
          }),
          component: () => import(/* webpackChunkName: "member-card-embed" */ '@/views/embed/PrintMemberCard.vue'),
        },
        {
          path: '*',
          redirect: {
            name: 'ErrorPage',
          },
        },
      ],
    },
    // --- Admin routes
    {
      path: '/admin',

      children: [
        {
          path: '/',
          beforeEnter() {
            window.location = `${process.env.VUE_APP_ADMIN_URL}/${USER_LANGUAGE}/${CHURCH_CODE}`;
          },
        },
        {
          path: 'recover-password',
          beforeEnter() {
            window.location
              = `${process.env.VUE_APP_ADMIN_URL}/${USER_LANGUAGE}/${CHURCH_CODE}/security/recuperar-senha/`;
          },
        },
      ],
    },
    // --- Not found route
    {
      path: '*',
      name: 'ErrorPage',
      props: (route) => ({
        message: route.query.message,
      }),
      component: () => import(/* webpackChunkName: "app-error-page" */ '@/views/ErrorPage.vue'),
    },
    // --- Voluts
    {
      path: '/third-parts',
      meta: {
        layout: 'DEFAULT',
        requiresAuth: true,
      },
      component: () => import(/* webpackChunkName: "voluts" */ '@/layouts/Default.vue'),

      children: [
        {
          path: 'voluts',
          name: 'volutsSingle',
          module: 'MOD_VOLUTS',
          component: () => import(/* webpackChunkName: "volutsSingle" */ '@/views/third-parts/Voluts.vue'),
        },
        {
          path: 'mykids',
          name: 'mykidsSingle',
          module: 'MOD_MYKIDS',
          component: () => import(/* webpackChunkName: "mykidsSingle" */ '@/views/third-parts/MyKids.vue'),
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (store.getters.churchBaseLoaded) {
    const routeWithModule = to.matched.find((record) => record.meta.module);

    //  eslint-disable-next-line max-len, vue/max-len
    const hasIncludedModule = store.getters.churchModules.some((moduleData) => moduleData.codigo === routeWithModule?.meta?.module);

    if (routeWithModule && !hasIncludedModule) {
      next({
        name: 'churchHome',
        replace: true,
      });
    }
  }

  if (to.matched.some((record) => record.meta.requiresAuth) && !store.getters.userLoggedIn) {
    // Checking if the route requires auth
    next({
      name: 'userAccess',
      query: { redirectTo: to.path },
      replace: true,
    });
  } else if (to.matched.some((record) => record.meta.onlyVisitors) && store.getters.userLoggedIn) {
    // Checking if the route is only for visitors
    next({
      name: 'churchHome',
      replace: true,
    });
  } else {
    next();
  }
});

export default router;
